interface Coordinates {
  latitude: number;
  longitude: number;
  accuracy?: number;
  // altitude?: number;
  // altitudeAccuracy?: number;
  // heading?: number;
  // speed?: number;
}

interface Position {
  coords: Coordinates;
  timestamp?: number;
}

export interface Location {
  latitude: any;
  longitude: any;
  accuracy?: any;
}

export const getLocation = (
  onSuccess: (location: Location) => void,
  onError?: () => void
) => {
  const options: PositionOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (position: Position) => {
    const location: Location = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy,
    };

    onSuccess(location);
  };

  const error = () => {
    if (onError) {
      onError();
    }
  };

  navigator.geolocation.watchPosition(success, error, options);
};

// Example usage locator
// getLocation(
//   (location: Location) => {
//     console.log(
//       `Current location: (${location.latitude}, ${location.longitude}, ${location.accuracy})`
//     );
//   },
//   () => {
//     console.error("Failed to retrieve location");
//   }
// );
