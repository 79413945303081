import {
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { CableColor, CableType } from "../enums/enums";

interface ClientFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const ClientForm: React.FC<ClientFormProps> = ({ onSubmit, onClose }) => {
  const [accountId, setAccountId] = useState("");
  const [cableType, setCableType] = useState<CableType | "">("");
  const [cableColor, setCableColor] = useState<CableColor | "">("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [images, setImages] = useState<File[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Convert FileList to an array
      const filesArray = Array.from(event.target.files);

      // Append new files to the current images, limiting to 10
      const updatedImages = [...images, ...filesArray].slice(0, 10);

      setImages(updatedImages);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ accountId, cableType, cableColor, images });
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        sx={{ marginTop: 4 }}
        margin="dense"
        id="accountId"
        name="accountId"
        label="Account ID"
        type="number"
        value={accountId}
        onChange={(e) => setAccountId(e.target.value)}
        required
        fullWidth
      />
      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel>Cable Type</InputLabel>
        <Select
          value={cableType}
          onChange={(e) => setCableType(e.target.value as CableType)}
        >
          {Object.values(CableType).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: 2 }}>
        <InputLabel>Cable Color</InputLabel>
        <Select
          value={cableColor}
          onChange={(e) => setCableColor(e.target.value as CableColor)}
        >
          {Object.values(CableColor).map((color) => (
            <MenuItem key={color} value={color}>
              {color}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Image Upload */}
      <input
        accept="image/*"
        id="upload-images"
        type="file"
        multiple
        onChange={handleImageUpload}
        style={{ marginTop: "16px" }}
      />
      <Typography variant="body2" color="textSecondary">
        {images.length}/10 images selected
      </Typography>
      {/* Image Display with Delete Option */}
      {images.length > 0 && (
        <div style={{ marginTop: "16px" }}>
          {images.map((image, index) => (
            <Grid container alignItems="center" key={index} spacing={1}>
              <Grid item xs={9}>
                <Typography variant="body2">{image.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveImage(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
      <Grid
        container
        spacing={2}
        marginTop={3}
        sx={{
          justifyContent: "space-between",
        }}
      >
        {/* <Grid item>
          <Button
            onClick={handleClearImages}
            sx={{
              color: "#242323",
            }}
          >
            Исчисти слике
          </Button>
        </Grid> */}
        <Grid item>
          <Button
            onClick={onClose}
            sx={{
              color: "#242323",
            }}
          >
            Close
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#292828",
              "&:hover": {
                backgroundColor: "#0d0b0b",
              },
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ClientForm;
