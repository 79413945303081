import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import StorageIcon from "@mui/icons-material/Storage";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ClosetForm from "./components/CreateCloset";
import BoxForm from "./components/CreateBox";
import ClientForm from "./components/CreateClient";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CodefixClient from "../../services/CodefixClient";
import {
  CableColor,
  CableType,
  SplitterType,
  SupplyCableType,
} from "./enums/enums";

interface GoogleModalProps {
  open: boolean;
  onClose: () => void;
  coordinate: [number, number];
}

export interface ClientFormSubmitData {
  accountId: string;
  cableType: CableType;
  cableColor: CableColor;
  images: File[];
  latitude: number;
  longitude: number;
}

export interface BoxFormSubmitData {
  name: string;
  description: string;
  splitters: SplitterType[];
  supplies: SupplyCableType[];
  images: File[];
  latitude: number;
  longitude: number;
}

const GoogleModal: React.FC<GoogleModalProps> = ({
  open,
  onClose,
  coordinate,
}) => {
  const [step, setStep] = useState<"selection" | "form">("selection");
  const [selectedType, setSelectedType] = useState<string>("");
  const client = new CodefixClient();

  const handleSelection = (type: string) => {
    setSelectedType(type);
    setStep("form"); // Move to the form step
  };

  const handleClosetFormSubmit = (data: BoxFormSubmitData) => {
    const latitude =
      coordinate?.[0] !== undefined ? coordinate[0].toString() : null;
    const longitude =
      coordinate?.[1] !== undefined ? coordinate[1].toString() : null;

    // Check for undefined values before proceeding
    if (!latitude || !longitude) {
      console.error("Coordinate values are missing.");
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("splitters", JSON.stringify(data.splitters));
    formData.append("supplies", JSON.stringify(data.supplies));
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    // Append each image file to the FormData
    data.images.forEach((image) => {
      formData.append("images", image);
    });

    // Send the FormData instead of JSON
    client.requesтClosetCreateBox(formData);
    onClose();
    setStep("selection");
  };

  const handleClientFormSubmit = (data: ClientFormSubmitData) => {
    const latitude =
      coordinate?.[0] !== undefined ? coordinate[0].toString() : null;
    const longitude =
      coordinate?.[1] !== undefined ? coordinate[1].toString() : null;

    // Check for undefined values before proceeding
    if (!latitude || !longitude) {
      console.error("Coordinate values are missing.");
      return;
    }

    const formData = new FormData();
    formData.append("accountId", data.accountId);
    formData.append("cableType", data.cableType);
    formData.append("cableColor", data.cableColor);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    // Append each image file to the FormData
    data.images.forEach((image) => {
      formData.append("images", image);
    });

    // Send the FormData instead of JSON
    client.requestMapCreateClient(formData);
    onClose();
    setStep("selection"); // Reset the step
  };

  const handleBoxFormSubmit = (data: BoxFormSubmitData) => {
    const latitude =
      coordinate?.[0] !== undefined ? coordinate[0].toString() : null;
    const longitude =
      coordinate?.[1] !== undefined ? coordinate[1].toString() : null;

    // Check for undefined values before proceeding
    if (!latitude || !longitude) {
      console.error("Coordinate values are missing.");
      return;
    }

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("splitters", JSON.stringify(data.splitters));
    formData.append("supplies", JSON.stringify(data.supplies));
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);

    // Append each image file to the FormData
    data.images.forEach((image) => {
      formData.append("images", image);
    });

    // Send the FormData instead of JSON
    client.requestMapCreateBox(formData);
    onClose();
    setStep("selection"); // Reset the step
  };

  const renderSelectionStep = () => (
    <DialogContent>
      <Typography variant="h6" align="center">
        Тип на објект
      </Typography>
      <Typography variant="body2" align="center" marginBottom={5}>
        Избери што сакаш да креираш
      </Typography>
      <Grid container spacing={5} justifyContent="center">
        <Grid item>
          <Button onClick={() => handleSelection("ормар")}>
            <DoorBackIcon
              fontSize="large"
              sx={{
                paddingRight: 1,
                color: "#292828",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#292828",
              }}
            >
              Ормар
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleSelection("кутија")}>
            <StorageIcon
              fontSize="large"
              sx={{
                paddingRight: 1,
                color: "#292828",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#292828",
              }}
            >
              Кутија
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleSelection("корисник")}>
            <AssignmentIndIcon
              fontSize="large"
              sx={{
                paddingRight: 1,
                color: "#292828",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                color: "#292828",
              }}
            >
              Корисник
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );

  const renderFormStep = () => (
    <>
      {/* Each form component will have its own submit button within the form */}
      {selectedType === "корисник" && (
        <ClientForm
          onSubmit={handleClientFormSubmit}
          onClose={() => {
            onClose();
            setStep("selection");
          }}
        />
      )}
      {selectedType === "ормар" && (
        <ClosetForm
          onSubmit={handleClosetFormSubmit}
          onClose={() => {
            onClose();
            setStep("selection");
          }}
        />
      )}
      {selectedType === "кутија" && (
        <BoxForm
          onSubmit={handleBoxFormSubmit}
          onClose={() => {
            onClose();
            setStep("selection");
          }}
        />
      )}
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setStep("selection");
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 2,
        }}
      >
        {step === "form" && (
          <IconButton
            onClick={() => setStep("selection")}
            sx={{ marginRight: "auto" }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          sx={{ textAlign: "left", flexGrow: 1, paddingLeft: 2 }}
        >
          {step === "selection" ? "Избери Тип" : `Креирај ${selectedType}`}
        </Typography>
        <IconButton
          onClick={() => {
            onClose();
            setStep("selection");
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {step === "selection" ? renderSelectionStep() : renderFormStep()}
      </DialogContent>
    </Dialog>
  );
};

export default GoogleModal;
