import { useState } from "react";
import { Box } from "@mui/material";
import { AppPathEnum } from "../../enums/path-enum";
import { UserRole } from "../../interfaces/types";
import { useAuth } from "../../auth/core/Auth";
import { capitalizeFirstLetter } from "../../core/utils/utils";
import "../../styles/header.scss";
import UserInfo from "./UserInfo";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const activeMenu = window.location.pathname;
  const { user } = useAuth();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Hamburger Menu Icon */}
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Mobile Full-Screen Menu */}
      <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
        <ul>
          {(() => {
            if (user && user.role === UserRole.Admin) {
              return Object.values(AppPathEnum).map((path) => (
                <li key={path} onClick={toggleMenu}>
                  <a href={path}>{capitalizeFirstLetter(path.split("/")[1])}</a>
                </li>
              ));
            } else if (user && user.role === UserRole.User) {
              return Object.values(AppPathEnum).map((path) => {
                if (path === AppPathEnum.MAIN) {
                  return (
                    <li key={path} onClick={toggleMenu}>
                      <a href={path}>
                        {capitalizeFirstLetter(path.split("/")[1])}
                      </a>
                    </li>
                  );
                }
                return null; // Hide other menu items for regular users
              });
            }
          })()}
        </ul>

        {user ? (
          <Box className="mobile-user-info">
            <UserInfo user={user} />
          </Box>
        ) : null}
      </div>

      {/* Desktop Header (only visible on larger screens) */}
      <Box className="header-container">
        <Box className="header-title">
          <h2>{process.env.REACT_APP_COMPANY_NAME}</h2>
          <p>Software Development Services</p>
        </Box>

        <Box className="header-menu">
          <ul>
            {(() => {
              if (user && user.role === UserRole.Admin) {
                return Object.values(AppPathEnum).map((path) => (
                  <li
                    key={path}
                    className={`header-menu-item ${
                      activeMenu === path ? "active" : ""
                    }`}
                  >
                    <a
                      className={`header-link ${
                        activeMenu === path ? "active" : "inactive"
                      }`}
                      href={path}
                    >
                      {capitalizeFirstLetter(path.split("/")[1])}
                    </a>
                  </li>
                ));
              } else if (user && user.role === UserRole.User) {
                return Object.values(AppPathEnum).map((path) => {
                  if (path === AppPathEnum.MAIN) {
                    return (
                      <li
                        key={path}
                        className={`header-menu-item ${
                          activeMenu === path ? "active" : ""
                        }`}
                      >
                        <a
                          className={`header-link ${
                            activeMenu === path ? "active" : "inactive"
                          }`}
                          href={path}
                        >
                          {capitalizeFirstLetter(path.split("/")[1])}
                        </a>
                      </li>
                    );
                  }
                  return null; // Hide other menu items for regular users
                });
              }
            })()}
          </ul>
        </Box>

        <Box className="header-bottom">
          <UserInfo user={user!} />
        </Box>
      </Box>
    </>
  );
};
