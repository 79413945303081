import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuth } from "../../../auth/core/Auth";
import { UserRole } from "../../../interfaces/types";
import { LanClientDto } from "../GoogleMaps";

interface LanClientDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  selectedLanClient: LanClientDto | null;
  onDelete: (user: LanClientDto) => void; // Callback to handle deletion
}

const LanClientDetailsDialog: React.FC<LanClientDetailsDialogProps> = ({
  open,
  onClose,
  selectedLanClient,
  onDelete,
}) => {
  const { user } = useAuth();
  const isAdmin = user!.role === UserRole.Admin;

  const handleDelete = () => {
    if (selectedLanClient) {
      onDelete(selectedLanClient);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 2,
        }}
      >
        <Typography variant="h6">
          LAN Client - {selectedLanClient?.accountId}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Typography variant="body1">
            <strong>Account ID:</strong> {selectedLanClient?.accountId}
          </Typography>
          <Typography variant="body1">
            <strong>First Name:</strong> {selectedLanClient?.firstName || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Last Name:</strong> {selectedLanClient?.lastName || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Address:</strong> {selectedLanClient?.address || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>VLAN:</strong> {selectedLanClient?.vlan || "N/A"}
          </Typography>
          <Typography variant="body1">
            <strong>Coordinates:</strong>{" "}
            {selectedLanClient?.coordinate
              ? `${selectedLanClient.coordinate[0]}, ${selectedLanClient.coordinate[1]}`
              : "N/A"}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          p={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Button onClick={onClose} sx={{ mt: 0, color: "#242323" }}>
              Close
            </Button>
          </Grid>
          {isAdmin && (
            <Grid item>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
                sx={{
                  backgroundColor: "#d32f2f",
                  "&:hover": {
                    backgroundColor: "#b71c1c",
                  },
                }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LanClientDetailsDialog;
