import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

interface MarkerDialogProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  coordinates: [number, number] | null; // Update to allow null values
}

const MarkerDialog = ({
  open,
  onClose,
  onEdit,
  coordinates,
}: MarkerDialogProps) => {
  const isValidCoordinates =
    coordinates && Array.isArray(coordinates) && coordinates.length === 2;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Marker</DialogTitle>
      <DialogContent>
        {isValidCoordinates ? (
          <Typography>
            Coordinates: {coordinates[0].toFixed(5)},{" "}
            {coordinates[1].toFixed(5)}
          </Typography>
        ) : (
          <Typography>Invalid coordinates</Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: "16px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          onClick={onEdit}
          color="primary"
          variant="contained"
          disabled={!isValidCoordinates}
          sx={{
            backgroundColor: "#292828",
            "&:hover": {
              backgroundColor: "#0d0b0b",
            },
          }}
        >
          Edit Destination
        </Button>
        <Button
          onClick={onClose}
          //   color="secondary"
          //   variant="outlined"
          sx={{
            color: "#242323",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkerDialog;
