import {
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { SplitterType, SupplyCableType } from "../enums/enums";

interface ClosetFormProps {
  onSubmit: (data: any) => void;
  onClose: () => void;
}

const ClosetForm: React.FC<ClosetFormProps> = ({ onSubmit, onClose }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [splitters, setSplitters] = useState<SplitterType[]>([]);
  const [supplies, setSupplies] = useState<SupplyCableType[]>([]);
  const [images, setImages] = useState<File[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImages((prevImages) => [...prevImages, ...filesArray].slice(0, 10)); // Limit to 10 images
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveSplitter = (index: number) => {
    setSplitters((prevSplitters) =>
      prevSplitters.filter((_, i) => i !== index)
    );
  };

  const handleRemoveSupply = (index: number) => {
    setSupplies((prevSupplies) => prevSupplies.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name,
      description,
      splitters,
      supplies,
      images,
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        marginTop: "2rem",
      }}
    >
      <TextField
        margin="dense"
        id="name"
        name="name"
        label="Име"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        fullWidth
      />
      <TextField
        margin="dense"
        id="description"
        name="description"
        label="Опис"
        type="text"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />

      {/* Splitters */}
      <TextField
        select
        margin="dense"
        id="splitterType"
        name="splitterType"
        label="Сплитери"
        value=""
        onChange={(e) =>
          setSplitters([...splitters, e.target.value as SplitterType])
        }
        fullWidth
      >
        {Object.values(SplitterType).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <Grid container spacing={1} pl={2}>
        {splitters.map((splitter, index) => (
          <Grid item xs={12} key={index}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={10}>
                <Typography variant="body2">{splitter}</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveSplitter(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Supplies */}
      <TextField
        select
        margin="dense"
        id="supplyCable"
        name="supplyCable"
        label="Доводен кабел"
        value=""
        onChange={(e) =>
          setSupplies([...supplies, e.target.value as SupplyCableType])
        }
        fullWidth
      >
        {Object.values(SupplyCableType).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <Grid container spacing={1} pl={2}>
        {supplies.map((supply, index) => (
          <Grid item xs={12} key={index}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={10}>
                <Typography variant="body2">{supply}</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveSupply(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Image Upload */}
      <input
        accept="image/*"
        id="upload-images"
        type="file"
        multiple
        onChange={handleImageUpload}
        style={{ marginTop: "16px" }}
      />
      <Typography variant="body2" color="textSecondary">
        {images.length}/10 images selected
      </Typography>
      {images.length > 0 && (
        <div style={{ marginTop: "16px" }}>
          {images.map((image, index) => (
            <Grid container alignItems="center" key={index} spacing={1}>
              <Grid item xs={9}>
                <Typography variant="body2">{image.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveImage(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
      <Grid
        container
        spacing={2}
        marginTop={3}
        sx={{
          justifyContent: "space-between",
        }}
      >
        {/* <Grid item>
          <Button
            onClick={() => setImages([])}
            sx={{
              color: "#242323",
            }}
          >
            Clear Images
          </Button>
        </Grid> */}
        <Grid item>
          <Button
            onClick={onClose}
            sx={{
              color: "#242323",
            }}
          >
            Close
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#292828",
              "&:hover": {
                backgroundColor: "#0d0b0b",
              },
            }}
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ClosetForm;
