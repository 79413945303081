/* eslint-disable class-methods-use-this */
import axios from "axios";
import { UserViewModel } from "../interfaces/types";
import toast from "react-hot-toast";
import webConfig from "../config/config";
import { AuthModel } from "../auth/core/AuthModels";
import axiosApi from "../auth/AxiosApi";
import { LanClientDto } from "../components/google-maps/GoogleMaps";

class CodefixClient {
  async authenticate(email: string, password: string): Promise<AuthModel> {
    const userSessionRequest = {
      email,
      password,
    };

    const response = await axios
      .post(`${webConfig.apiBaseUrl}/login`, userSessionRequest, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.data !== "Unauthorized") {
          toast.error(error.response.data);
        }
      });

    return response;
  }

  async receiveUsers(companyId: string): Promise<any> {
    try {
      const response = await axiosApi.get(
        `${webConfig.apiBaseUrl}/users/${companyId}`
      );

      return response.data;
    } catch (error) {
      throw new Error("Error fetching users");
    }
  }

  async updateUser(event: UserViewModel): Promise<any> {
    try {
      const request = await axiosApi.put(
        `${webConfig.apiBaseUrl}/users`,
        event
      );

      return request;
    } catch (error) {
      throw new Error("Error updating user");
    }
  }

  async requestUser(event: UserViewModel): Promise<any> {
    try {
      const request = await axiosApi.post(
        `${webConfig.apiBaseUrl}/users`,
        event
      );

      return request;
    } catch (error) {
      throw new Error("Error creating user");
    }
  }

  async deactivateUser(req: UserViewModel): Promise<any> {
    try {
      const request = await axiosApi.put(
        `${webConfig.apiBaseUrl}/users/deactivate`,
        req
      );

      return request;
    } catch (error) {
      throw new Error("Error deactivating user");
    }
  }

  async requestMapCreateClient(formData: FormData): Promise<any> {
    try {
      const request = await axiosApi.post(
        `${webConfig.apiBaseUrl}/clients`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request;
    } catch (error) {
      throw new Error("Error creating client");
    }
  }

  async requestUpdateClient(
    clientId: string,
    formData: FormData
  ): Promise<any> {
    try {
      const request = await axiosApi.patch(
        `${webConfig.apiBaseUrl}/clients/${clientId}`, // PATCH request to update the client
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request.data; // return the updated data
    } catch (error) {
      throw new Error("Error updating client");
    }
  }

  async receivedClients(): Promise<any> {
    try {
      const request = await axiosApi.get(`${webConfig.apiBaseUrl}/clients`);

      return request.data;
    } catch (error) {
      throw new Error("Error fetching clients");
    }
  }

  async requestMapCreateBox(formData: FormData): Promise<any> {
    try {
      const request = await axiosApi.post(
        `${webConfig.apiBaseUrl}/boxes`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request;
    } catch (error) {
      throw new Error("Error creating box");
    }
  }

  async requestUpdateBox(boxId: string, formData: FormData): Promise<any> {
    try {
      const request = await axiosApi.patch(
        `${webConfig.apiBaseUrl}/boxes/${boxId}`, // PATCH request to update the box
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request.data; // return the updated data
    } catch (error) {
      throw new Error("Error updating box");
    }
  }

  async receivedBoxes(): Promise<any> {
    try {
      const request = await axiosApi.get(`${webConfig.apiBaseUrl}/boxes`);

      return request.data;
    } catch (error) {
      throw new Error("Error fetching boxes");
    }
  }

  async requesтClosetCreateBox(formData: FormData): Promise<any> {
    try {
      const request = await axiosApi.post(
        `${webConfig.apiBaseUrl}/closets`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request;
    } catch (error) {
      throw new Error("Error creating box");
    }
  }

  async requestUpdateCloset(
    closetId: string,
    formData: FormData
  ): Promise<any> {
    try {
      const request = await axiosApi.patch(
        `${webConfig.apiBaseUrl}/closets/${closetId}`, // PATCH request to update the box
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return request.data; // return the updated data
    } catch (error) {
      throw new Error("Error updating box");
    }
  }

  async receivedClosets(): Promise<any> {
    try {
      const request = await axiosApi.get(`${webConfig.apiBaseUrl}/closets`);

      return request.data;
    } catch (error) {
      throw new Error("Error fetching boxes");
    }
  }

  async requestUpdateLanClient(
    lanClientId: string,
    formData: FormData
  ): Promise<any> {
    try {
      const request = await axiosApi.patch(
        `${webConfig.apiBaseUrl}/google-maps/${lanClientId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return request.data; // return the updated data
    } catch (error) {
      throw new Error("Error updating box");
    }
  }

  async requestDeleteLanClient(req: LanClientDto): Promise<any> {
    try {
      const request = await axiosApi.put(
        `${webConfig.apiBaseUrl}/google-maps/deactivate`,
        {
          req,
        }
      );

      return request;
    } catch (error) {
      throw new Error("Error deleting user");
    }
  }

  async receivedLanClients(): Promise<any> {
    try {
      const request = await axiosApi.get(`${webConfig.apiBaseUrl}/google-maps`);

      return request.data;
    } catch (error) {
      throw new Error("Error fetching lan clients");
    }
  }

  //TODO: Modified function for the reset user password
  //   async resetPassword(email: string): Promise<void> {
  //     await this.request(`${this.BASE_URL}/reset-password`, 'POST', {}, { email });
  //   }

  // TODO: modified function for the logout user and remove all data from the storage
  logout(): void {
    localStorage.clear();
    window.location.href = "/";
  }
}

export default CodefixClient;
