export enum SplitterType {
  ONE_TO_ONE = "1/1",
  ONE_TO_TWO = "1/2",
  ONE_TO_FOUR = "1/4",
  ONE_TO_EIGHT = "1/8",
}

export enum SupplyCableType {
  ONE_BY_ONE = "1/1",
  ONE_BY_TWO = "1/2",
  ONE_BY_FOUR = "1/4",
  ONE_BY_SIX = "1/6",
  ONE_BY_TWELVE = "1/12",
  ONE_BY_TWENTY_FOUR = "1/24",
  ONE_BY_FORTY_EIGHT = "1/48",
}

export enum CableType {
  ONE_BY_ONE = "1/1",
  ONE_BY_TWO = "1/2",
  ONE_BY_FOUR = "1/4",
  ONE_BY_SIX = "1/6",
  ONE_BY_TWELVE = "1/12",
  ONE_BY_TWENTY_FOUR = "1/24",
  ONE_BY_FORTY_EIGHT = "1/48",
}

export enum CableColor {
  RED = "црвена",
  BLUE = "плава",
  GREEN = "зелена",
  YELLOW = "жута",
  BLACK = "црна",
  WHITE = "бела",
  ORANGE = "помаранџаста",
  PURPLE = "лилакова",
  BROWN = "кафена",
  GRAY = "сива",
  PINK = "розева",
  CYAN = "цијан",
  MAGENTA = "магента",
  INDIGO = "индиго",
  AMBER = "амбер",
  LIGHT_BLUE = "светло плава",
  LIGHT_GREEN = "светло зелена",
  LIGHT_YELLOW = "светло жута",
  LIGHT_ORANGE = "светло помаранџаста",
  LIGHT_PURPLE = "светло лилакова",
  LIGHT_BROWN = "светло кафена",
  LIGHT_GRAY = "светло сива",
  LIGHT_PINK = "светло розева",
  LIGHT_CYAN = "светло цијан",
  LIGHT_MAGENTA = "светло магента",
  LIGHT_INDIGO = "светло индиго",
  LIGHT_AMBER = "светло амбер",
}
