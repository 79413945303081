import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./styles/index.scss";
import { useState } from "react";
import GoogleModal from "./GoogleModal";

interface GoogleButtonProps {
  coordinate: [number, number];
}

const GoogleButton = ({ coordinate }: GoogleButtonProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} className="google-button">
        <AddIcon style={{ color: "#fff" }} />
      </Button>

      <GoogleModal
        open={openModal}
        onClose={handleClose}
        coordinate={coordinate}
      />
    </>
  );
};

export default GoogleButton;
