import React from "react";
import { Box } from "@mui/material";
import { Button } from "antd";
import { UserViewModel } from "../../interfaces/types";
import CodefixClient from "../../services/CodefixClient";
import "../../styles/header.scss"; // Adjust the path if necessary

interface UserInfoProps {
  user: UserViewModel;
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const client = new CodefixClient();

  return (
    <Box className="user-info">
      <Box className="header-user-info">
        <h3>
          {user.firstName} {user.lastName}
        </h3>
      </Box>
      <Button
        type="default"
        htmlType="submit"
        className="logout-button"
        onClick={client.logout}
      >
        Logout
      </Button>
    </Box>
  );
};

export default UserInfo;
