import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import webConfig from "../../../config/config";
import { ClientDto } from "../GoogleMaps";
import { useAuth } from "../../../auth/core/Auth";
import { UserRole } from "../../../interfaces/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { CableColor, CableType } from "../enums/enums";

interface ClientDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  selectedClient: ClientDto | null;
  onSaveChanges: (data: any) => void; // This will be called when the save changes button is clicked
}

const ClientDetailsDialog: React.FC<ClientDetailsDialogProps> = ({
  open,
  onClose,
  selectedClient,
  onSaveChanges,
}) => {
  const [accountId, setAccountId] = useState<number | null>(
    selectedClient?.accountId ?? null
  );
  const [cableType, setCableType] = useState<CableType | "">(
    selectedClient?.cableType ?? ""
  );

  const [cableColor, setCableColor] = useState<CableColor | "">(
    selectedClient?.cableColor ?? ""
  );

  // const [firstName, setFirstName] = useState<string | null>(
  //   selectedClient?.firstName ?? ""
  // );
  // const [lastName, setLastName] = useState<string | null>(
  //   selectedClient?.lastName ?? ""
  // );
  const [images, setImages] = useState<string[]>(selectedClient?.images ?? []);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [removedImages, setRemovedImages] = useState<string[]>([]);
  const { user } = useAuth();

  const handleSaveChanges = () => {
    const formData = new FormData();
    formData.append("accountId", accountId!.toString());
    formData.append("cableType", cableType!);
    formData.append("cableColor", cableColor!);
    // formData.append("firstName", firstName!);
    // formData.append("lastName", lastName!);

    removedImages.forEach((image) => {
      formData.append("removedImages", image);
    });

    // Append new images to FormData
    newImages.forEach((image) => {
      formData.append("newImages", image);
    });

    onSaveChanges(formData);
    onClose();
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setNewImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  const handleRemoveImage = (image: string) => {
    setImages((prevImages) => prevImages.filter((img) => img !== image));
    setRemovedImages((prevRemoved) => [...prevRemoved, image]);
  };

  const handleRemoveNewImage = (index: number) => {
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 2,
        }}
      >
        <Typography variant="h6">
          Корисник - {selectedClient?.accountId}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Form to edit details */}
        <Box mt={2}>
          <TextField
            fullWidth
            label="Account ID"
            value={accountId}
            onChange={(e) => setAccountId(parseInt(e.target.value, 10) || null)}
            type="number"
            variant="outlined"
            margin="dense"
            disabled={user!.role !== UserRole.Admin}
          />
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Cable Type</InputLabel>
            <Select
              value={cableType}
              onChange={(e) => setCableType(e.target.value as CableType)}
              disabled={user!.role !== UserRole.Admin}
            >
              {Object.values(CableType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Cable Color</InputLabel>
            <Select
              value={cableColor}
              onChange={(e) => setCableColor(e.target.value as CableColor)}
              disabled={user!.role !== UserRole.Admin}
            >
              {Object.values(CableColor).map((color) => (
                <MenuItem key={color} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            variant="outlined"
            margin="dense"
            disabled={user!.role !== UserRole.Admin}
          />
          <TextField
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            variant="outlined"
            margin="dense"
            disabled={user!.role !== UserRole.Admin}
          /> */}

          <Box mt={2}>
            <input
              accept="image/*"
              id="upload-image"
              type="file"
              multiple
              onChange={handleImageUpload}
            />
          </Box>

          {/* Show new images to be uploaded */}
          {newImages.length > 0 && (
            <Grid container spacing={2} mt={2}>
              {newImages.map((image, index) => (
                <>
                  <Grid item key={index} xs={6} sx={{ position: "relative" }}>
                    <Typography variant="body2">{image.name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleRemoveNewImage(index)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </>
              ))}
            </Grid>
          )}

          {/* Show list of existing images */}
          {images && images.length > 0 ? (
            <Grid container spacing={2} mt={2}>
              {images.map((image, index) => (
                <Grid item key={index} xs={6} sx={{ position: "relative" }}>
                  {/*  eslint-disable-next-line */}
                  <img
                    src={`${webConfig.apiBaseUrl}/public/clients/${image}`}
                    alt={`client image ${index}`}
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        `${webConfig.apiBaseUrl}/public/clients/${image}`
                      )
                    }
                  />
                  {user!.role === UserRole.Admin && (
                    <IconButton
                      aria-label="remove image"
                      size="small"
                      onClick={() => handleRemoveImage(image)}
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: -8,
                        backgroundColor: "rgba(245, 22, 22, 0.8)",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "rgba(245, 22, 22, 1)",
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" mt={2}>
              No images available.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          p={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Button onClick={() => onClose()} sx={{ mt: 0, color: "#242323" }}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={{
                // mt: 3,
                backgroundColor: "#292828",
                "&:hover": {
                  backgroundColor: "#0d0b0b",
                },
              }}
              onClick={handleSaveChanges}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ClientDetailsDialog;
