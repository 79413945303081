import { Box } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { Header } from "../../components/header/Header";
import "./styles/index.scss";

const Management = () => {
  return (
    <>
      <Box className="management-header">
        <Header />
      </Box>
      <Box className="management-content">
        <Box className="vacation-plan-container">{/* Mapping */}</Box>
      </Box>
    </>
  );
};

export default Management;
