// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 15%;
  height: 100%;
  background-color: #1c1c1c;
  color: white;
}
@media (max-width: 768px) {
  .users-header {
    width: 100%;
    height: 55px;
    /* Adjust the height based on your design */
    z-index: 1001;
  }
}

.users-content {
  position: absolute;
  left: 15%;
  top: 0;
  width: 85%;
  padding-bottom: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}
@media (max-width: 768px) {
  .users-content {
    left: 0;
    width: 100%;
    top: 55px;
    /* This should match the height of the .users-header */
    height: calc(100% - 55px);
    /* Adjust height to be full screen minus header */
  }
}

#create-user-btn {
  margin: 1% 2.5%;
  background-color: #1c1c1c;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
}

.select-role {
  margin-top: 7px;
}

.users-table-container {
  margin: auto;
  width: 95% !important;
  overflow-x: auto;
}

.users-table-head {
  background-color: #dfdfdf;
}

.users-table-row > * {
  min-width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/screens/users/styles/index.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,OAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;AADJ;AAEI;EARJ;IASQ,WAAA;IACA,YAAA;IACA,2CAAA;IACA,aAAA;EACN;AACF;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,MAAA;EACA,UAAA;EACA,oBAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AAAI;EARJ;IASQ,OAAA;IACA,WAAA;IACA,SAAA;IACA,sDAAA;IACA,yBAAA;IACA,iDAAA;EAGN;AACF;;AAAA;EACI,eAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,YAAA;EACA,qBAAA;EACA,gBAAA;AAGJ;;AAAA;EACI,yBAAA;AAGJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":["$mobile: 480px;\n$tablet: 768px;\n.users-header {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 15%;\n    height: 100%;\n    background-color: #1c1c1c;\n    color: white;\n    @media (max-width: $tablet) {\n        width: 100%;\n        height: 55px;\n        /* Adjust the height based on your design */\n        z-index: 1001;\n    }\n}\n\n.users-content {\n    position: absolute;\n    left: 15%;\n    top: 0;\n    width: 85%;\n    padding-bottom: 2rem;\n    background-color: #f5f5f5;\n    min-height: 100vh;\n    @media (max-width: $tablet) {\n        left: 0;\n        width: 100%;\n        top: 55px;\n        /* This should match the height of the .users-header */\n        height: calc(100% - 55px);\n        /* Adjust height to be full screen minus header */\n    }\n}\n\n#create-user-btn {\n    margin: 1% 2.5%;\n    background-color: #1c1c1c;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    font-size: 12px;\n    padding: 5px 10px;\n}\n\n.select-role {\n    margin-top: 7px;\n}\n\n.users-table-container {\n    margin: auto;\n    width: 95% !important;\n    overflow-x: auto;\n}\n\n.users-table-head {\n    background-color: #dfdfdf;\n}\n\n.users-table-row>* {\n    min-width: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
