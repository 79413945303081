import React, { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Modal } from "@mui/material";
import "./styles/index.scss";
import FilterListIcon from "@mui/icons-material/FilterList";

interface FilterSectionProps {
  showClients: boolean;
  setShowClients: React.Dispatch<React.SetStateAction<boolean>>;
  showBoxes: boolean;
  setShowBoxes: React.Dispatch<React.SetStateAction<boolean>>;
  showClosets: boolean;
  setShowClosets: React.Dispatch<React.SetStateAction<boolean>>;
  showLanClients: boolean;
  setShowLanClients: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  showClients,
  setShowClients,
  showBoxes,
  setShowBoxes,
  showClosets,
  setShowClosets,
  showLanClients,
  setShowLanClients,
}) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const toggleModal = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };

  return (
    <>
      {/* For Desktop */}
      <Box className="filter-section-desktop">
        <FormControlLabel
          control={
            <Checkbox
              checked={showClients}
              onChange={() => setShowClients(!showClients)}
              sx={{
                color: "#000", // Color when not checked
                "&.Mui-checked": {
                  color: "#000", // Color when checked
                },
              }}
            />
          }
          label="Корисници"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showBoxes}
              onChange={() => setShowBoxes(!showBoxes)}
              sx={{
                color: "#000",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
          }
          label="Кутије"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showClosets}
              onChange={() => setShowClosets(!showClosets)}
              sx={{
                color: "#000",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
          }
          label="Ормари"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showLanClients}
              onChange={() => setShowLanClients(!showLanClients)}
              sx={{
                color: "#000",
                "&.Mui-checked": {
                  color: "#000",
                },
              }}
            />
          }
          label="Лан Корисници"
        />
      </Box>

      {/* For Mobile */}
      <Box className="filter-button-mobile">
        <Button onClick={toggleModal}>
          <FilterListIcon sx={{ color: "#fff" }} />
        </Button>
      </Box>

      <Modal open={isMobileModalOpen} onClose={toggleModal}>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showClients}
                onChange={() => setShowClients(!showClients)}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="Корисници"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showBoxes}
                onChange={() => setShowBoxes(!showBoxes)}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="Кутије"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showClosets}
                onChange={() => setShowClosets(!showClosets)}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="Ормари"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showLanClients}
                onChange={() => setShowLanClients(!showLanClients)}
                sx={{
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label="Лан Корисници"
          />
          <Button
            variant="contained"
            sx={{
              marginTop: "2rem",
              backgroundColor: "#292828",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#0d0b0b",
              },
            }}
            onClick={toggleModal}
          >
            Затвори
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default FilterSection;
